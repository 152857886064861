// Navbar
$navbar-max-width: 1100px;
$navbar-gray: #404040;
$desktop-container-width: 1200px;
$max-full-width: 1600px;

// Global
$white: #FFFFFF;
$black: #000000;
$dark: #221F1F;
$light-gray: #EDEDEE;
$blue: #1c69d3;
$section-border: #DEDEDE;
$input-border-gray: #CACACA;
$breadcrumb-border: #D5D5D5;
$medium-gray: #77787B;
$dark-gray: #414141;
$primary-blue: #0067DB;
$primary-blue-lightened: #0066FF;
$header-font-color: $dark;
$error-red: #FF1744;
$valid-green: #43a047;
$secondary-btn-border: #979797;
$contract-border-color: #D8D8D8;
$select-selected-color: #EEEEEE;
$charge-green: #7ED321;
$light-grey-tone: #E6E7E8;
$progress-buffer-background-color: #e6e7e8;
$border-gray: #cacaca;
$progress-buffer-background-color: #e6e7e8;

// MINI Colors
$mini-red: #AC2430;

// Fonts
$helvetica: 'Helvetica Neue', Helvetica, Arial, sans-serif;

$mini-sans-serif-bold: 'mini-sans-serif-bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$mini-sans-serif-regular: 'mini-sans-serif-regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$mini-serif-bold: 'mini-serif-bold', serif;
$mini-serif-regular: 'mini-serif-regular', serif;

// Animations
$cb: cubic-bezier(0.505, 0.015, 0.46, 0.985);

// Breakpoints
$medium: 760px;
$small: 600px;
$xsmall: 400px;
$large: 900px;

$mobile: 425px;
$tablet: 768px;

// Font Weight
$font-weight-300: 300;
$font-weight-400: 400;

// Font Size 
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-20: 20px;