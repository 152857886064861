 @import '../../_shared/shared/_styles/variables.scss';
.footer {
     width:100%; 
     bottom:0; 
     position:absolute;
     margin-top:20px;
}

.space-top-5{
margin-top: 5%;
}
.space-top-4{
  margin-top: 4%;
}
.space-top-3{
  margin-top: 3%;
}
.space-top-2{
  margin-top: 2%;
}
.space-bottom-2{
  margin-bottom: 2%;
}
.space-bottom-3{
  margin-bottom: 3%;
}
.space-bottom-4{
  margin-bottom: 4%;
}
.space-bottom-5{
  margin-bottom: 5%;
}
.space-bottom-10{
  margin-bottom: 10%;
}

.center {
  text-align: center;
}

.width-20{
  width: 20%;
}

.show-cursor
{ cursor: pointer; }

em {float:left; color:$error-red;}
.error input {background-color:#E3C3C5;}
.error ::-webkit-input-placeholder { color: #999; }
.error :-moz-placeholder { color: #999; }
.error ::-moz-placeholder {color: #999; }
.error :ms-input-placeholder { color: #999; }

//Progress Bar

.mat-progress-bar .mat-progress-bar-buffer {
  background-color: $light-grey-tone;
}

.mat-progress-bar-fill::after {
  background-color: $blue;
}

.mat-progress-bar.error .mat-progress-bar-fill:after {
  background-color: $error-red;
}

.mat-mdc-radio-checked {
  .mdc-radio__outer-circle{
    border-color: $blue !important;
  }

  .mdc-radio__inner-circle {
    border-color: $blue !important;
  }
}

.form-group {
  position: relative;
  // input fields
  .form-control {
    border-radius: 0;
    border: 1px solid $input-border-gray;
    font-size: 12px;
    height: 40px;
    box-shadow: 0 1px 0 transparent;
    outline: none;
    -webkit-appearance: none;
    padding: 0 24px 0 15px;
    background: $white;
    font-family: $helvetica;

    &:focus, &.ng-valid {
      height: 40px;
      margin-bottom: 0;
    }

    &:focus {
      box-shadow: 0 1px 0 $primary-blue !important;
      border-bottom-color: $primary-blue !important;
    }

    &.ng-valid {
      box-shadow: 0 1px 0 $valid-green !important;
      border-bottom-color: $valid-green !important;
    }

    &.ng-valid:focus {
      box-shadow: 0 1px 0 $valid-green !important;
      border-bottom-color: $valid-green !important;
    }
  }


  &.has-error,
  &.error {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    .mat-select {
      border-bottom-color: $error-red !important;
      height: 40px;
      margin-bottom: 0;
      box-shadow: 0 1px 0 $error-red !important;

      &:focus {
        border: 1px solid $input-border-gray;
      }
    }
  }
}



.btn, .btn:disabled {
  font-family: $helvetica;
  background: $blue;
  color: $white;
  border-radius: 0;
  width: 100%;
  display: block;
  margin-bottom: 20px;
  text-shadow: none;
  transition: all cubic-bezier(.505,.015,.46,.985) .15s;
  &:disabled {
    cursor: not-allowed;
    opacity: .5; 
  }
  &:hover {
    background: none;
    color: $primary-blue;
  }
}
.btn {
  border-radius: 0;
  font-size: $font-size-11;
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px 30px;
  border: 1px solid $blue;
  opacity: 1;
  cursor: pointer;
 
}

.btn.btn-secondary, .btn:disabled.btn-secondary {
  background: $white;
  border: 1px solid $secondary-btn-border;
  color: $black;
  &:hover {
    border:1px solid $black;
  }
}

.action-buttons .btn {
  width: auto;
  display: inline-block;
  margin: 20px 0 0 10px;
}
  